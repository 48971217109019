
<template>
  <div class="wxq_side_bar">
    <div class="wxq_side_logo">
      <img class="wxq_side_img" src="@/assets/img/logo.png" alt="">
      <span>继续医学教育管理端</span>
    </div>
    <div class="wxq_side_menu">
      <el-menu text-color="#7D8592" active-text-color="#F7F9FC" :router="true" :unique-opened="false" :default-active="$route.path">
        <sidebar-template v-for="(item,index) in menu" :key="String(index)" :item="item"></sidebar-template>
      </el-menu>
    </div>
  </div>
</template>
<script>
import menuTreeAPI from '@apis/backStageEndAPI/menuTreeAPI'
import sidebarTemplate from './sidebarTemplate';

export default {
  name: 'sidebar',
  components: {
    sidebarTemplate
  },
  data() {
    return {
      menu: [],
      defaultActive: '',
      docMenu: {
        url: '/doctorManage'
      },
      iconList: [
        { 
          ind: 0, 
          icon: require('@/assets/img/backStageImg/courseware-icon.png'),
          active_icon: require('@/assets/img/backStageImg/courseware-active-icon.png')
        },
        { 
          ind: 1,
          icon: require('@/assets/img/backStageImg/mycourse-icon.png') ,
          active_icon: require('@/assets/img/backStageImg/mycourse-active-icon.png')
        },
        { 
          ind: 2, 
          icon: require('@/assets/img/backStageImg/exam-icon.png') ,
          active_icon: require('@/assets/img/backStageImg/exam-active-icon.png')
        },
        { 
          ind: 3, 
          icon: require('@/assets/img/backStageImg/myexamine-icon.png') ,
          active_icon: require('@/assets/img/backStageImg/myexamine-active-icon.png')
        },
        { 
          ind: 4, 
          icon: require('@/assets/img/backStageImg/system-icon.png') ,
          active_icon: require('@/assets/img/backStageImg/system-active-icon.png')
        },
        { 
          ind: 5, 
          icon: require('@/assets/img/backStageImg/questionnaire.png') ,
          active_icon: require('@/assets/img/backStageImg/questionnaire-active-icon.png')
        },
        {
          ind: 6,
          icon: require('@/assets/img/backStageImg/consult-icon.png') ,
          active_icon: require('@/assets/img/backStageImg/consult-active-icon.png')
        },
        {
          ind: 7,
          icon: require('@/assets/img/backStageImg/lecturer-icon.png') ,
          active_icon: require('@/assets/img/backStageImg/lecturer-active-icon.png')
        }
      ],
    };
  },
  watch: {
    $route() {
      // this.handleSelect(this.defaultActive)
    }
  },
  mounted() {
    this.getMenuTree()
  },
  methods: {
    getMenuTree() {
      menuTreeAPI.menuList().then(res => {
        this.menu = []
        if (res[0]?.children?.length) {
          res[0].children.forEach((element,index) => {
            this.iconList.forEach((el,ind) => {
              if(index == ind) {
                element.icon = el.icon
                element.selectedIcon = el.active_icon
              }
            })
            this.menu.push(element)
          });
        }
      })
    }
  }
};
</script>
<style lang="scss" scoped>
$width: 100%;
$height: 100%;
.wxq_side_bar {
	width: $width;
	height: $height;
	.wxq_side_logo {
		margin-bottom: 22px;
		padding: 30px 18px 0 18px;
		text-align: center;
		.wxq_side_img {
			display: block;
			margin: 0 auto 15px;
			width: 139px;
			height: 50px;
		}
		span {
			color: #004e8d;
			font-weight: 800;
			font-size: 18px;
			font-family: PingFang-SC-Heavy, PingFang-SC;
			line-height: 25px;
		}
	}
	.wxq_side_menu {
		width: $width;
		.el-menu {
			border: none;
			background: transparent;
			color: #fff;
			font-size: 16px;
		}
		::v-deep.el-submenu__title,
		.el-menu-item {
			color: #7d8592;
			text-align: left;
			font-weight: 800;
			font-size: 16px;
			font-family: PingFang-SC-Heavy, PingFang-SC;
		}
	}
}
</style>
