<template>
  <div class="backStage" >
    <el-container :class="{fusion: params.from && params.from != 'unifiedVideo'}">
      <el-aside width="200px" v-show="params.from == null || params.from == 'unifiedVideo'">
        <el-scrollbar style="height: 100%;">
          <side-bar></side-bar>
        </el-scrollbar>
      </el-aside>
      <el-container class="wxq_main">
        <el-main :class="{main_height: params.from && params.from != 'unifiedVideo'}">
          <router-view :from="params.from"/>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import sideBar from "@/components/backStageComponent/layout/sideBar/sideBar";
// import headerBar from "@/components/backStageComponent/layout/headerBar/headerBar";
export default {
  name: "backStageEnd",
  // props: ['from'],
  components: {
    sideBar,
    // headerBar
  },
  data() {
    return {
      params: {
        from: null,
        path: '',
        token: ''
      }
    };
  },
  mounted() {
    this.params = this.$store.state.fusion
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
$width: 100%;
$height: 100%;

.backStage {
	width: $width;
	height: $height;
	.el-container {
		padding: 15px 0 15px 15px;
		width: $width;
		height: calc(100vh);
		background: #f4f9fd;
		.wxq_main {
			padding-left: 15px;
			height: calc(100vh - 30px);
		}
		.el-aside {
			height: $height;
			border-radius: 24px;
			background: #fff;
		}
		.el-container {
			.el-aside {
				min-width: 254px;
			}
			.el-header {
				padding: 0;
				height: 66px;
				line-height: 66px;
			}
			.el-main {
				padding: 0 15px 0 0;
				height: calc(100vh - 30px);
			}
		}
	}
	.el-container.fusion {
		padding: 0;
	}
}
::v-deep .el-scrollbar__wrap {
	overflow-x: hidden;
	overflow-y: scroll;
}

::v-deep .el-scrollbar__bar.is-horizontal {
	display: none;
}
</style>
