<template>
  <div>
    <el-submenu v-if="item.children && item.children.length" :key="'sub_'+item.menuId" :index="String(item.menuId)" :unique-opened=uniqueOpened >
      <template slot="title" >
        <!-- 一级菜单 -->
        <div class="menu_font" @click="clickMenu">
          <!-- <img v-if="item.path" :src="require('@/assets/img/backStageImg/myCourse/' + [item.icon === '#' ? 'courseware-icon.png' : item.icon])" class="wxq_menu_icon" alt="" > -->
          <img v-if="item.path" :src="chooseIcon(item,$route.path)" class="wxq_menu_icon" alt="" >
          <span>{{ item.menuName }}</span>
        </div>
      </template>
      <template v-for="(child,index) in item.children">
        <!-- 一级子级菜单 -->
        <sidebar-template v-if="child.children && child.children.length > 0" :key="'template_'+index" :item="child"></sidebar-template>
        <el-menu-item v-else :key="child.menuId" :index="child.path" class="wxq_child" @click="clickMenu(child)">
          <span slot="title"><i class="wxq_child_icon"></i>{{ child.menuName }}</span>
        </el-menu-item>
      </template>
    </el-submenu>
    <el-menu-item v-else :index="item.path" :key="item.menuId" @click="clickMenu(item)">
      <!-- 无子级菜单 -->
      <i :class="item.icon"></i>
      <!-- <img :src="require('@/assets/img/backStageImg/myCourse/' + [item.icon === '#' ? 'courseware-icon.png' : item.icon])" class="wxq_menu_icon" alt=""> -->
      <img v-if="item.path" :src=" item.path == $route.path ? item.selectedIcon : item.icon  " class="wxq_menu_icon" alt="" >
      <span slot="title">{{ item.menuName }}</span>
    </el-menu-item>
  </div>
</template>
<script>
export default {
  name: 'sidebarTemplate',
  props: ['item'],
  data() {
    return {
      uniqueOpened: true,
      current: 0,
    };
  },
  mounted() {},
  methods: {
    clickMenu(obj) {
      if (this.$route.path != obj.path) {
        this.$router.push(obj.path);
      }
    },
    chooseIcon(item,route) {
      var n = 0
      for(var i = 0; i < item.children.length; i++) {
         if(item.children[i].path == route) {
           n = 1
         }
       }
       if(n == 1) {
         return item.selectedIcon
       } else {
         return item.icon
       }
  }
}
};
</script>
<style lang="scss" scoped>
@import '../../../../assets/css/backStageEndCSS/siderBar';
.wxq_child {
  text-align: center;
  span {
    position: relative;
    .wxq_child_icon::before {
      content: ' ';
      position: absolute;
      left: -14px;
      top: 8px;
      width: 6px;
      height: 6px;
      background-color: #7d8592;
      border-radius: 50%;
    }
  }
}
.wxq_menu_icon {
  width: 24px;
  height: 24px;
  margin: 0 16px 0 4px;
}
.menu_font{
  color: #7d8592 !important;
  font-weight: 800;
  font-size: 16px;
}
</style>
